<template>
  <div class="gonggaoxq">
    <div class="header">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item to="/logistics">物流服务</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex">
        <div class="xq flex-1">
          <el-descriptions class="margin-top"  :column="1" >
            <el-descriptions-item label="仓库名称">{{datas.name}}</el-descriptions-item>
            <el-descriptions-item label="所在地">{{datas.provinceName}}{{datas.cityName}}{{datas.areaName}}{{datas.address}}</el-descriptions-item>
            <el-descriptions-item label="用途类型">{{datas.usageTypeName}}</el-descriptions-item>
            <el-descriptions-item label="租售形式">{{datas.rentalTypeName}}</el-descriptions-item>
            <el-descriptions-item label="仓库类型">{{datas.warehouseTypeName}}</el-descriptions-item>
            <el-descriptions-item label="建筑类型">{{datas.buildingTypeNames}}</el-descriptions-item>
            <el-descriptions-item label="地坪处理">{{datas.floorNames}}</el-descriptions-item>
            <el-descriptions-item label="建筑结构">{{datas.buildingStructureNames }}</el-descriptions-item>
            <el-descriptions-item label="可租面积">{{datas.rentalArea}}㎡</el-descriptions-item>
            <el-descriptions-item label="起租期限" v-if="datas.typeId==1">{{datas.startingRentalTerm}}月</el-descriptions-item>
            <el-descriptions-item label="仓库主图" v-if="datas.typeId==1"><el-image style="width: 80px;height: 80px" :preview-src-list="[datas.mainImages]" :src="datas.mainImages"></el-image></el-descriptions-item>
            <el-descriptions-item label="租金" v-if="datas.typeId==1">{{datas.rentPrice}}元/{{datas.rentUnit}}</el-descriptions-item>
            <el-descriptions-item label="租金范围" v-if="datas.typeId==2">{{datas.rentPrice}}-{{datas.feesPrice}}元/{{datas.rentUnit}}</el-descriptions-item>
<!--            <el-descriptions-item label="附件">-->
<!--              <fujian  :files="datas.accessorySet"></fujian>-->
<!--            </el-descriptions-item>-->
          </el-descriptions>
        </div>
        <div class="xq width300">
          <el-descriptions class="margin-top"  :column="1" >
            <el-descriptions-item label="联系人">{{ datas.contacts }}</el-descriptions-item>
            <el-descriptions-item label="联系人电话">{{ datas.phone }}</el-descriptions-item>
          </el-descriptions>
          <div class="button">
             <el-button type="primary" size="small" @click.stop.native="zixun()">仓库留言咨询</el-button>
             <shoucang  style="display: inline-block;margin-left: 20px" :articleType="datas.typeId==1?'warehouse-release':'warehouse-demand'" :articleId="$route.query.id"></shoucang>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="发送" width="500px" append-to-body :visible.sync="dialogTableVisible">
      <el-form ref="form" :model="shen" label-width="100px" :rules="rules" :inline="true">
        <el-form-item label="咨询详情" prop="detail">
          <el-input rows="5" class="inputWidth mb8" type="textarea" v-model="shen.detail"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input   v-model="shen.contacts" class="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="手机号"   prop="phone">
          <el-input  v-model.number="shen.phone" class="inputWidth"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin: 20px 0 0 160px" @click="submitForm()">保存</el-button>
          <el-button @click="dialogTableVisible=!dialogTableVisible">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import fujian from '../common/fujian.vue'
import {warehousedetail, consultMessage} from "../../request/moudle/logistics";
import shoucang from "../../components/shoucang.vue";
export default {
  name: "gonggaoxq",
  components:{
    shoucang,
    fujian
  },
  data(){
    return {
      datas:'',
      dialogTableVisible:false,
      form:{},
      tenantId: '',
      shen:{},
      zxid:'',
      rules: {
        detail: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
          { type: 'number', message: '手机号必须为数字值'}
        ]
      },
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    submitForm(){
      let typeId = this.$route.query.typeId;
      if(typeId==1){
        this.shen.productType = "logistics-release"
      }else {
        this.shen.productType = "logistics-demand"
      }
      this.shen.productId = this.$route.query.id
      this.$api.logistics.consultMessage(this.shen).then((res)=>{
        this.dialogTableVisible=false
        this.$message({
          type: "success",
          message: "提交成功!"
        });
      })
    },

    loadxq(){
      this.$api.logistics.warehousedetail({
        id:this.$route.query.id,
        typeId:this.$route.query.typeId
      }).then((res)=>{
          this.datas=res.data
          this.datas.buildingTypeNames = res.data.buildingTypeNames.join(',')
          this.datas.floorNames = res.data.floorNames.join(',')
          this.datas.buildingStructureNames = res.data.buildingStructureNames.join(',')
      })
    },
    
    zixun(){
      this.shen={}
      // this.zxid=item.id
      this.dialogTableVisible=true
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 80px;
}
.button {
  //text-align: center;
  margin-top: 10px;
}
.width300{
  width:300px ;
  margin-left:65px;
}
.inputWidth {
   width: 300px;
}
.mb8 {
   margin-bottom:8px;
}
  .gonggaoxq{
     background: #F5F5F5;
    .fujian{
      margin-top: 50px;
      .as{
        line-height: 25px;
        color:  #409EFF;
      }
    }
    .header{
      height: 180px;
      background: url("../../assets/gxjy/gxjy.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .xq{
        background: #fff;
        margin-top: 20px;
        padding: 20px;
      }
      .title{
        color: #292929;
        text-align: center;
        font-weight: bolder;
        font-size: 20px;
      }
      .h1{
        line-height: 50px;
        text-align: center;
        span{
          margin-right: 50px;
        }
      }
    }
  }
</style>