<template>
  <div class="btns">
    <el-button  size="mini"  @click="collect" :class="{ active: isCollect}"  type="primary"><i class="el-icon-star-off mr2"></i>{{ isCollect ? '已收藏' : '收藏' }}</el-button>
  </div>
</template>
<script>
import {MessageBox} from "element-ui";
import config from "../utils/config";

export default{
  props:['articleType','articleId'],
  data() {
    return {
      isCollect:false
    }
  },
  created() {
    if(this.common.getSessionStorage('userInfo')){
      this.humanIsCollect()
    }
  },
  methods:{
    humanIsCollect(){
      let obj = {};
      obj.articleId = this.articleId;
      obj.articleType =  this.articleType
      this.$api.rlzy.humanIsCollected(obj).then((res)=>{
        if(res.success){
          this.isCollect = res.data;
        }
      })
    },
    collect(){
      if(!this.common.getSessionStorage('userInfo')){
        this.$msgbox.confirm('您还未登录, 是否去登录?', '提示', {
          confirmButtonText: '去登录',
          cancelButtonText: '继续浏览',
          type: 'warning'
        }).then(() => {
          window.location.replace(config.pchref)
        })
        return
      }
      if(this.isCollect){
        //取消收藏
        this.$api.rlzy.humanCancelCollect({
          articleId:this.articleId,
          articleType: this.articleType,
        }).then((res)=>{
          console.log(res);
          this.$message.success('取消收藏成功！')
        })
      }else{
        //收藏
        this.$api.rlzy.humanCollect({
          articleId:this.articleId,
          articleType:  this.articleType,
        }).then((res)=>{
          console.log(res);
          this.$message.success('收藏成功！')
        })
      }
      this.isCollect = !this.isCollect;
    }
  }
}
</script>
<style lang="scss" scoped>
.btns{
  .collect {
    .mr2 {
      margin-right: 2px;
    }
  }
}

</style>
